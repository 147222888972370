import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import TreatmentHero from "../../components/treatment-hero";
import ReviewSection from "../../components/review-section";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Faq from "../../components/faq";
import { checkPropertiesForNull } from "../../utils/objectHelper";
import { SafeHtmlParser } from "../../components/safe-html-parser";

const DentalCrownsPage = () => {
	const data = useStaticQuery(graphql`
		query {
			reviews {
				reviewCount
			}
			schemaLogo: wpMediaItem(title: { eq: "Logo-footer" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: TRACED_SVG
						)
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
      pageData: wpPage(slug: {eq: "dental-crowns"}) {
        slug
        seoFieldGroups {
          metaTitle
          metaDescription
          localBusinessSchema
          openGraphDescription
          openGraphTitle
          productSchema
          image {
            node {
              altText
              publicUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                  original {
                    width
                    height
                  }
                }
              }
            }
          }
        }
        dentalCrownsFieldGroups {
          dentalCrownsIntroSection {
            heading
            subHeading
            title
            subtitle
            description
            cta {
              title
              target
              url
            }
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
          dentalCrownsReviewSection {
            heading
            subHeading
          }
          dentalCrownsTextSection {
            heading
            content
          }
          dentalCrownsFaqSection {
            heading
            fAQS {
              nodes {
                ... on WpFaq {
                  faqFields {
                    question
                    answer
                  }
                }
              }
            }
          }
        }
      }
		}
	`);

  const {
    pageData: { seoFieldGroups, dentalCrownsFieldGroups, slug },
  } = data;

  const {
    dentalCrownsIntroSection,
    dentalCrownsReviewSection,
    dentalCrownsTextSection,
    dentalCrownsFaqSection,
  } = dentalCrownsFieldGroups;

	const siteUrl = data.site.siteMetadata.siteUrl;

	const serviceSchema = {
		"@context": "https://schema.org/",
		"@type": "Product",
    "@id": `https://www.dentalhousepinner.co.uk/treatments/${slug}/#${slug}`,
    image: `https://www.dentalhousepinner.co.uk${seoFieldGroups?.image?.node.localFile.publicURL}`,
    mpn: `https://www.dentalhousepinner.co.uk/treatments/${slug}`,
    sku: `https://www.dentalhousepinner.co.uk/treatments/${slug}`,
		description: `${seoFieldGroups?.productSchema}`,
		logo: `https://www.dentalhousepinner.co.uk${data.schemaLogo?.localFile.publicURL}`,
		name: "Dental House Pinner",
		url: "https://www.dentalhousepinner.co.uk",
		brand: {
			"@type": "Organization",
      logo: `https://www.dentalhousepinner.co.uk${data.schemaLogo?.localFile.publicURL}`,
      image: `https://www.dentalhousepinner.co.uk${seoFieldGroups?.image?.node.localFile.publicURL}`,
			name: "Dental House Pinner",
			url: "https://www.dentalhousepinner.co.uk",
		},
		aggregateRating: {
			"@type": "AggregateRating",
			reviewCount: `${data.reviews.reviewCount}`,
			ratingValue: "4.9",
			bestRating: "5",
			worstRating: "1",
		},
		review: {
			"@type": "Review",
			url: "https://www.dentalhousepinner.co.uk",
			datePublished: "2023-08-23",
			reviewBody:
				"As someone terrified of dentists, it's been a pleasure being a patient of Dental House. Dr Shah's been a godsend, a truly welcoming presence who never fails to put you at ease. Clearly explains each procedure and how it should feel. The whole team are great!",
			author: {
				"@type": "Person",
				name: "N R",
			},
			reviewRating: {
				"@type": "Rating",
				ratingValue: "5",
				bestRating: "5",
				worstRating: "1",
			},
			publisher: {
				"@type": "Organization",
				name: "Dental House Pinner",
				sameAs: "https://www.dentalhousepinner.co.uk",
			},
		},
	};

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Treatments",
				item: {
					url: `${siteUrl}/treatments`,
					id: `${siteUrl}/treatments`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Dental Crowns",
				item: {
					url: `${siteUrl}/treatments/dental-crowns`,
					id: `${siteUrl}/treatments/dental-crowns`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				<script type="application/ld+json">
					{JSON.stringify(serviceSchema)}
				</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
        title={seoFieldGroups?.metaTitle}
        description={seoFieldGroups?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/treatments/dental-crowns`,
          title: `${seoFieldGroups?.openGraphTitle}`,
          description: `${seoFieldGroups?.openGraphDescription}`,
          images: [
            {
              url: `${seoFieldGroups?.image?.node.localFile.publicURL}`,
              width: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.width}`,
              height: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.height}`,
              alt: `${seoFieldGroups?.image?.node.altText}`,
            },
          ],
        }}
      />

      <Layout>
        {dentalCrownsIntroSection &&
          !checkPropertiesForNull(dentalCrownsIntroSection, ["heading"]) && (
            <TreatmentHero
              heading1={dentalCrownsIntroSection?.title}
              smallHeadingTopText={dentalCrownsIntroSection?.subtitle}
              img={
                dentalCrownsIntroSection?.image.node?.localFile.childImageSharp
                  .gatsbyImageData
              }
              imgAlt={dentalCrownsIntroSection?.image.node?.altText}
              heading2={dentalCrownsIntroSection?.heading}
              headingSmall={dentalCrownsIntroSection?.subHeading}
              text={
                <SafeHtmlParser
                  htmlContent={dentalCrownsIntroSection?.description}
                />
              }
              btnLink={dentalCrownsIntroSection?.cta.url}
              btnText={dentalCrownsIntroSection?.cta?.title}
            />
          )}

        <ReviewSection
          heading={
            dentalCrownsReviewSection?.heading ||
            "Reviews From Our Dentistry Patients"
          }
          smallheading={
            dentalCrownsReviewSection?.subHeading || "WHAT PEOPLE SAY ABOUT US"
          }
          page="Root canal treatments"
        />

        {dentalCrownsTextSection &&
          !checkPropertiesForNull(dentalCrownsTextSection, ["heading"]) && (
            <section className="bg-primary py-5 py-lg-7">
              <Container>
                <Row>
                  <Col className="text-center">
                    <h2 className=" text-white fs-1 mb-5 ">
                      {dentalCrownsTextSection?.heading}
                    </h2>
                    <div className="content-white">
                      <SafeHtmlParser htmlContent={dentalCrownsTextSection?.content} />
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          )}

        {dentalCrownsFaqSection &&
          !checkPropertiesForNull(dentalCrownsFaqSection, ["fAQS"]) && (
            <Faq
              heading={dentalCrownsFaqSection?.heading}
              faqs={dentalCrownsFaqSection?.fAQS.nodes}
            />
          )}
      </Layout>
		</>
	);
};

export default DentalCrownsPage;
